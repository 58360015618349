import {create} from 'zustand'
import axios from 'axios'
const jobStore = (set) => ({
    jobLoading : false,
    isDisabled : false,
    newChoice : false,
    setNewChoice : (value)=>(set({newChoice : value})),
    setIsDisabled : (value)=>(set({isDisabled : value})),
    resetJob : (value)=>(set({jobChoice : null,jobDetails : null,isDisabled : false}))
})

const useJobStore=create(jobStore)

export default useJobStore