import React, { useState } from 'react'
import Message from './Message'
import { Button } from '@mui/material'
import { useInputStore } from '../stores/InputStore'
import useJobStore from '../stores/JobStore'
import useCourseStore from '../stores/CourseStore'
import Tool from './Tooltip'

import useAuthStore from '../stores/AuthStore'

const Options = ({lastMessageRef}) => {
    const [isDisabled,setIsDisabled]=useState(false)
    
    const {userSkills}=useCourseStore((state)=>({userSkills : state.userSkills}))
    const {setMessageToBeShown, messageToBeShown}=useInputStore((state)=>({setMessageToBeShown : state.setMessageToBeShown,messageToBeShown : state.messageToBeShown}))
    const {method}=useAuthStore((state)=>({method : state.method}))
    const buttonStyle = {
        marginLeft: "43px",
      };
      const containerStyle = {
        display: "flex",
        alignItems: "center", // Align items vertically in the center
    };
  return (
    <div>
    <div ref={lastMessageRef} >
    
    
        <Message content={"Are you interested in going for certification for any desired skill?"}/>
        <div className="flex">
       <div className='flex flex-row '>
        <Button
          style={buttonStyle}
          variant="contained"
          component="label"
          disabled={isDisabled}
          onClick={()=>{setMessageToBeShown({certifications : true})
          setIsDisabled(true)}}
        >
          Yes
        </Button>
       
       </div>
        

      <div className='flex flex-row'>
      <Button
        style={buttonStyle}
          variant="contained"
          component="label" 
          disabled={isDisabled}
          onClick={()=>{
            setMessageToBeShown({certifications : false, closingRemark : true})
            setIsDisabled(true)
          }}
        >
          No
        </Button>
        
      </div>

    </div>
    </div>
    </div>
  )
}

export default Options