import {create} from 'zustand'

const courseStore = (set) => ({
    choice : null,
    topChoice : null,
    isAllowed : false,
    setIsAllowed : (value)=>(set((state)=>({isAllowed : value}))),
    setChoice : (value)=>(set({choice : value})),
    setTopChoice : (value)=>(set({topChoice : value})),
    loading : false,
    userSkills : [],
    courses : [],
    setUserSkills : (value)=>(set({userSkills : value})),
    setLoading : (value)=>(set({loading : value})),
    setCourses :(value)=>(set({courses : value})),
    
})

const useCourseStore=create(courseStore)

export default useCourseStore