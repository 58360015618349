import React, { useRef, useEffect, useState, useCallback } from "react";
import S3 from "react-aws-s3";
import { Button, TextField, Typography } from "@mui/material";
import Message from "./Message";
import Loading from './Loading'
import { Auth } from "aws-amplify";

import JsontoDoc from "../utils/JsontoDoc";
import { useInputStore } from "../stores/InputStore";
import { getJobIdFromUrl } from "../utils/LinkedInJob";
import useErrorStore from "../stores/ErrorStore";
import { generateRandomPassword } from "../utils/Auth";
import { isFileTypeAllowed, generateFileName, getFileContentType } from "../utils/UploadFile";

import useCourseStore from "../stores/CourseStore";
import toast from "react-hot-toast";
import useAuthStore from "../stores/AuthStore";
import VerificationMail from "./VerificationMail";
import useJobStore from "../stores/JobStore";
import Options from "./Options";
import Certifications from "./Certifications";
import ClosingRemark from "./ClosingRemark";
import axios from "axios";
import TopSkillCourses from "./TopSkillCourses";
// installed using npm install buffer --save
window.Buffer = window.Buffer || require("buffer").Buffer;

function Upload(props) {
  // Create a ref to the last message element
  const lastMessageRef = useRef(null);
  const {isAuth,setAuth,email,setEmail,method,setMethod, setFileLocation, fileLocation,}=useAuthStore((state)=>({isAuth:state.isAuth,setAuth:state.setAuth,setEmail : state.setEmail, email : state.email,setMethod : state.setMethod, method : state.method, fileLocation : state.fileLocation, setFileLocation : state.setFileLocation}));
  const [checkInterval, setCheckInterval] = useState(null);
  const [result, setResult] = useState(null);
  const [loading, setLoading]=useState(false)
  const [isDisabled, setIsDisabled]=useState(false)

  const { jobDetails,jobChoice,getJobDetails}=useJobStore((state)=>({getJobDetails : state.getJobDetails,jobDetails: state.jobDetails, jobChoice : state.jobChoice,isDisabled : state.isDisabled, setIsDisabled : state.setIsDisabled}))
  const {
    setIsVisible,
    setCurrentInputType,
    messageToBeShown,
    setMessageToBeShown,
    userInputData,
    setUserInputData
  } = useInputStore((state) => ({
    setIsVisible: state.setIsVisible,
    messageToBeShown : state.messageToBeShown,
    setMessageToBeShown: state.setMessageToBeShown,
    inputType: state.inputType,
    currentInputType: state.currentInputType,
    setCurrentInputType: state.setCurrentInputType,
    userInputData : state.userInputData,
    setUserInputData : state.setUserInputData
  }));
  const { error, setError } = useErrorStore((state) => ({
    error: state.error,
    setError: state.setError,
  }));
  const { setUserSkills, topChoice} = useCourseStore((state) => ({
  setUserSkills : state.setUserSkills,
    topChoice : state.topChoice
  }));
  useEffect(() => {

    if (lastMessageRef.current) {
      console.log(lastMessageRef.current)
     
      setTimeout(()=>{
        lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
      }, 1000)
    }
  }, [ loading, isAuth, messageToBeShown,error,userInputData]);
  useEffect(()=>{
    if(email && result && isDisabled)
      {
      
        emailVerification()
      }
  },[email,result])
  

  useEffect(() => {
    
    if(userInputData.linkedinurl && isDisabled)
      {
        if(validateLinkedInUrl(userInputData.linkedinurl))
          {
          setMethod({currentUsed : "linkedinurl"})
          getLinkedInData(userInputData.linkedinurl);
          }
          else
          {
            toast.error("Invalid LinkedIn URL. Please enter a valid LinkedIn URL.");
            setUserInputData({linkedinurl : ''})
            setIsVisible(true)
            setCurrentInputType("linkedinurl")
          }
      }
  }, [userInputData.linkedinurl]);
  useEffect(()=>{
    if(userInputData.email && isDisabled)
      {
        if (isValidEmail(userInputData.email)) {

                setEmail(userInputData.email)

              } else {
                toast.error("Invalid Email. Please enter a valid email.");
                setUserInputData({email: ''})
                setCurrentInputType("email")
                setIsVisible(true)
              }
      }
  },[userInputData.email])
  useEffect(()=>{
   async function getLinkedinJobData()
   {
    if(userInputData.joburl){
      let jobId =getJobIdFromUrl(userInputData.joburl)
      if(jobId)
        {
          setLoading(true)
          let response =await getJobDetails(jobId)
          if(response===null)
          {
            setLoading(false)
            setError({content : "Unable to extract the job details"})
          }
          else
          setMessageToBeShown({upload : true})
        }
        else
        {
          toast.error("Job URL is not valid. Please enter a valid job URL.");
          setUserInputData({joburl : ''})
          setIsVisible(true)
          setCurrentInputType('joburl')
        }
   }
    }
    getLinkedinJobData()
  },[userInputData.joburl])
  useEffect(()=>{
    
   if(result!==null && isDisabled)
    {
      if (
        (result.data.email === "" ||
          result.data.email === " ") && !isAuth
      ) {
        setCurrentInputType("email");
        setIsVisible(true);
       
        setMessageToBeShown({email : true})
      } else if(!isAuth){ 
        
       setEmail(result.data.email)
      }
      setLoading(false);
    }
  },[result])

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const isVerified = useCallback(async () => {
    if (!isAuth && email !== " " && email !== "") {
      try {
        await Auth.signIn(email, "123");
        setAuth(true);
        clearInterval(checkInterval); // Stop checking when isAuth becomes true
      } catch (error) {
        if (error.code === "UserNotConfirmedException") {
          setAuth(false);
        }
        if (error.code === "NotAuthorizedException") {
          setAuth(true);
          clearInterval(checkInterval); // Stop checking on other errors too
        }
      }
    }
  }, [isAuth, email]);
  useEffect(() => {
    if (!isAuth) {
      // Start checking verification status every 90 milli seconds
      const intervalId = setInterval(isVerified, 90);
      setCheckInterval(intervalId);

      // Cleanup the interval when the component unmounts
      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      };
    }
  }, [isAuth, isVerified]);
  // the configuration information is fetched from the .env file

  const handleFileInput = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Check if the file type is allowed (docx, doc, or pdf)
      if (isFileTypeAllowed(file)) {
        // Start the upload process
        uploadFile(file, null);
      } else {
        // Display an error message or take appropriate action for invalid file types

        alert("Invalid file type. Please select a .docx, .txt, or .pdf file.");
      }
    }
  };

  function validateLinkedInUrl(url) {
    if (!url.startsWith('https://')) {
      url = 'https://' + url;
    }
    const regex = /^https:\/\/(www\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?$/;
    return regex.test(url);
  }
  async function getLinkedInData(url) {
    try {
      setLoading(true)
      const link =
        "https://rm6sec5xgoogq3ca7gfcfsuzfy0dmnez.lambda-url.ap-south-1.on.aws/";
      const data = {
        url: url,
      };
      
        let response = await fetch(link, {
          method: "POST",
          body: JSON.stringify(data),
        });

        let result = await response.json();

        if (!response.ok) {
          throw new Error(result.body.error);
        }

        if (result.body?.data?.error) {
          setLoading(false)
          setError({ content: "Unable to extract data from LinkedIn URL" });
          return;
        }
        let file = await JsontoDoc(result.body);
        uploadFile(file, url);
    } catch (error) {
      setLoading(false)
      setError({ content: "Unable to extract data from LinkedIn URL" });
    }
  }
  const matchingFunction = (location) => {
    setLoading(true);
 
    const myObject = {
      file: location,
    };
    setFileLocation(myObject.file);

    const serverUrl =
      "https://kcxky6flbrjpnj5db27qa6ue2u0dqqbp.lambda-url.ap-south-1.on.aws/skill";
    fetch(serverUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Setting the content type to JSON
      },

      body: JSON.stringify(myObject),
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (
          responseData.message !== "Successful extraction" &&
          responseData.message !== "Endpoint request timed out"
        ) {
          const error = new Error("Text extraction failed");
          error.details =
            "I am unable to process your resume. Please ensure it is in a supported format (PDF, DOC, DOCX) and try again. If the issue persists, consider reformatting your resume or uploading a different file.";
          throw error;
        } else if (responseData.message === "Endpoint request timed out") {
          const error = new Error("Endpoint request timed out");
          error.details =
            "It seems that the request took too long to complete. This could be due to a slow internet connection or a temporary issue with our server. Please try uploading your resume again.";
          throw error;
        }
        setResult(responseData);
        //if email is not present in the response
        
      })
      .catch((error) => {


        let errorMessage = {
          content:
            error.details ||
            `We are unable to process your ${
              method.lastUsed === "resume" ? "Resume" : "LinkedIn Profile"
            }. Please try again.`,
        };
        setLoading(false)

        setError(errorMessage);
        // Handling errors here
      });
  };

  async function signUp() {
    try {
      const password = generateRandomPassword(8);
      const username = email;
      const { user } = await Auth.signUp({
        username,
        password,
        attributes: {
          email,
        },
        autoSignIn: {
          // optional - enables auto sign in after user is confirmed
          enabled: true,
        },
      });

      isVerified(email);
    } catch (error) {
      if (error.code === "UsernameExistsException") {
        isVerified(email);
      }
    }
  }
  async function emailVerification() {
   
    if (!isAuth) {    
      await signUp();
    }
   
    //const skill=responseData.data[0].
   let skills=result.data.skills.sort((a,b)=>b.frequency - a.frequency)
    setUserSkills(skills.slice(0,5));
    setMessageToBeShown({ topSkillCourse : true})
  }
  const uploadFile = async (file, url = null) => {
    
    // the name of the file uploaded is used to upload it to S3
    const filename = generateFileName(file, url);
    setLoading(true)
    setIsDisabled(true);
    const result=await axios.post(`https://s5lmem3lg2zlt4hap5ribxjbdu0dljxx.lambda-url.ap-south-1.on.aws/api/puturl`,{key : filename});
    if(result.data.success===false)
    {
      throw new Error("Something went wrong. Please try again.");
    }
   
  
     axios.put(result.data.data , file)
      .then((data) =>{
       
        matchingFunction(`${'https://upskillztest.s3.ap-south-1.amazonaws.com/'}${filename}`)
      })
      .catch((err) => {
        //  console.error(err)
        setLoading(false)
        setError({content : "Something went wrong"});
      });
  };

  const buttonStyle = {
    marginLeft: "43px",
  };
  const containerStyle = {
    display: "flex",
    alignItems: "center", // Align items vertically in the center
  };

  return (
    <div >
     
      { messageToBeShown.upload && < div >
          <Message
        content={"Would you like to find the top skills in your resume currently?"}
        isCustomer={false}
      />
     
      <div className="flex max-sm:flex-col max-sm:space-y-4">
        <Button
          variant="contained"
          component="label"
           className="max-w-[300px]"
          disabled={isDisabled}
          style={buttonStyle}
        >
          Upload Resume
          <input
            type="file"
            accept=".pdf, .docx, .txt"
            onChange={(e) => {
              setMethod({currentUsed : 'resume'});
            
              handleFileInput(e);
            }}
            hidden
          />
        </Button>

        <Button
          variant="contained"
          component="label"
          disabled={isDisabled}
          style={buttonStyle}
          className="max-w-[300px]"
          onClick={() => {
          
            setIsDisabled(true);
            setIsVisible(true);
            setCurrentInputType("linkedinurl");
            setMessageToBeShown({linkedinurl : true})
          }}
        >
          Use LinkedIn Url
        </Button>

        {method.lastUsed  && fileLocation !== null && (
          <Button
            variant="contained"
            component="label"
             className="max-w-[300px]"
            disabled={isDisabled}
            onClick={() => {
           
              setIsDisabled(true);
              matchingFunction(fileLocation);
              setMethod({currentUsed : method.lastUsed});
            }}
            style={buttonStyle}
          >
            Use Previous{" "}
            {method.lastUsed === "resume" ? "Resume" : "LinkedIn URL"}
          </Button>
        )}
      </div>
        </div>
      }
      
      {userInputData.linkedinurl && messageToBeShown.linkedinurl && (
        <Message
          className={"linkedin"}
          isCustomer={true}
          content={userInputData.linkedinurl}
        />
      )}
{
        loading && <Loading  content={"Please wait while we process your request"}/>
      }
      
      {messageToBeShown.email && (
        <div >
          <Message
            isCustomer={false}
            content={"Please enter your email for verification purpose."}
          />
        </div>
      )}
      {userInputData.email && messageToBeShown.email && (
        <div >
          <Message
            className={"email"}
            isCustomer={true}
            content={userInputData.email}
          />
        </div>
      )}
      {email &&  error === null && !isAuth && (
        <div style={containerStyle}>
          <Message
            content={
              <span>
                Please verify your email by clicking on the link we sent on{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "blue",
                    textDecoration: "underline",
                  }}
                >
                  {email}
                </span>
              </span>
            }
            isCustomer={false}
          />
        </div>
      )}
      {
        messageToBeShown.verificationMail && isAuth &&  <div ><VerificationMail/></div>
      }
     {
      messageToBeShown.topSkillCourse && isAuth && <div><TopSkillCourses/></div>
     }
      {
        messageToBeShown.options && isAuth && <div ><Options/></div>
      }
     
      {
        messageToBeShown.certifications && isAuth && <Certifications />
      }
      {
        messageToBeShown.closingRemark && <div >
          <ClosingRemark/>
        </div>
      }
      <div ref={lastMessageRef}></div>
    </div>
  );
}
export default Upload;
