export function getJobIdFromUrl(url) {
    try {
      const urlObj = new URL(url);
  
      // Check if the URL is a LinkedIn job URL
      if (!urlObj.hostname.includes('linkedin.com') || !urlObj.pathname.includes('/jobs/')) {
        throw new Error('Not a LinkedIn job URL');
      }
  
      // Try to get the job ID from the query parameter
      const jobIdFromQuery = urlObj.searchParams.get('currentJobId');
      if (jobIdFromQuery) {
        return jobIdFromQuery;
      }
  
      // If not found, try to extract it from the URL path
      const regex = /\/jobs\/view\/(\d+)/;
      const match = url.match(regex);
      if (match && match[1]) {
        return match[1];
      }
  
      throw new Error('Job ID not found in the URL');
    } catch (error) {
        // console.log(error)
        return null
    }
  }
  
  