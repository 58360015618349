import {create} from 'zustand'

const authStore = (set) => ({
    isAuth: false,
    email : '',
    fileLocation : null,
    method : {
        lastUsed : null,
        currentUsed : null
    },
    isAllowed : false,
    setIsAllowed : (value)=>(set((state)=>({isAllowed : value}))),
    setMethod : (value)=>(set((state)=>({ method : {...state.method, ...value}}))),
    setFileLocation : (value)=>(set({fileLocation : value})),
    setEmail : (value)=>(set({email : value})),
    setAuth: (value) => set((state) => ({isAuth: value})),
});

const useAuthStore = create(authStore);

export default useAuthStore