import React from "react";
import avatar from "./assets/logo.png";
import { Avatar, Box, Typography } from "@mui/material";

export default function Header() {
  const textStyle = {
    fontFamily: "Open Sans", // Change this to your desired font
    fontSize: "25px", // Change the font size as needed
    fontWeight: "bold" // Change the font weight as needed
  };
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column', // This will center content vertically
    alignItems: 'center', // Center content horizontally
    justifyContent: 'center', // Center content vertically // Set the minimum height of the container to 100% of the viewport height
  };
  return (
    <div style={containerStyle}>
    <Box display="flex" alignItems="center">
      <Avatar src={avatar} alt="Logo" height="60px" />
      <Typography variant="h4" component="div" ml={2} style={textStyle}>
        Upskillz.ai
      </Typography>
    </Box>
    </div>
  );
}
