import React, { useEffect } from 'react'
import useCourseStore from '../stores/CourseStore'
import { useState } from 'react'
import Message from './Message'
import Loading from './Loading'
import Courses from './Courses'
import axios from 'axios'
import useErrorStore from '../stores/ErrorStore'
import { useInputStore } from '../stores/InputStore'
const TopSkillCourses = () => {
    const {topChoice, setTopChoice}=useCourseStore((state)=>({topChoice : state.topChoice, setTopChoice : state.setTopChoice}))
    const [loading, setLoading] = useState(false)
    const [courses, setCourses]=useState([])
    const {userSkills}=useCourseStore((state)=>({userSkills : state.userSkills}))
    const {setError}=useErrorStore((state)=>({setError : state.setError}))
    const {messageToBeShown, setMessageToBeShown}=useInputStore((state)=>({messageToBeShown : state.messageToBeShown, setMessageToBeShown: state.setMessageToBeShown}))
    const handleChoice =async (value) => {
        try{
            setLoading(true)
            window.dataLayer.push({
              'event' : "skill_click",
              'skill' : value
            })
            
            const response = await axios.post("https://s5lmem3lg2zlt4hap5ribxjbdu0dljxx.lambda-url.ap-south-1.on.aws/api/udemy", {
              skill: value,
            });
            
            setCourses(response.data.data.results);
            setLoading(false)
            if(messageToBeShown.topSkillCourse)
              setMessageToBeShown({options : true})
            }catch(error)
            {
              setLoading(false)
              setError({content : "Unable to fetch courses. Please try again later."})
            }
    }
    useEffect(()=>{
        if(topChoice)
        handleChoice(topChoice)
    },[topChoice])
  return (
   
    <div>
     <Message content={"Here are the top skills in your resume; please click on your desired skill below to get the online courses"} skills={true} choices={userSkills}/>
      {
        topChoice && <Message
        content={topChoice}
        isCustomer={true}
      />
      }
      {
        loading && <div >
           <Loading content={"Please wait while we fetch most relevant courses for you."}/>
        </div>
      }
      { !loading && courses.length>0 && (
        <div >
          <Courses courses={courses}/>
        </div>
      )}
      
    </div>
  )
}

export default TopSkillCourses