let dataList =[
   
    'JavaScript', 'Python', 'Java', 'C#', 'C++', 'Ruby', 'Swift', 'Kotlin', 'TypeScript', 'PHP',
    'Go', 'Rust', 'Scala', 'Perl', 'R',
  
  
    'HTML5', 'CSS3', 'React.js', 'Angular', 'Vue.js', 'Redux',
    'Webpack', 'Responsive Design', 'Bootstrap', 'Tailwind CSS',
  
    
    'Node.js', 'Express.js', 'Django', 'Flask', 'Spring Boot', 'ASP.NET Core', 'Ruby on Rails',
    'GraphQL', 'RESTful APIs', 'Microservices Architecture', 'Serverless Computing',
 
    'React Native', 'Flutter', 'Swift (iOS Development)', 'Kotlin (Android Development)', 'Xamarin',
    'Mobile UI/UX Design', 'Cross-platform Development',
  
    'SQL', 'MySQL', 'PostgreSQL', 'MongoDB', 'SQLite', 'Redis', 'Oracle Database',
    'Microsoft SQL Server', 'Data Modeling', 'Database Administration',
  

    'AWS (Amazon Web Services)', 'Azure', 'Google Cloud Platform (GCP)', 'Infrastructure as Code (IaC)', 'Containers and Kubernetes', 'Cloud Security', 'Cloud Architecture',
  
  
    'Git', 'Jenkins', 'Docker', 'Kubernetes', 'Terraform', 'Ansible', 'Continuous Integration (CI)',
    'Continuous Deployment (CD)', 'Monitoring and Logging', 'Agile and DevOps Methodologies',
  
   
    'TensorFlow', 'PyTorch', 'Scikit-learn', 'Pandas', 'NumPy',
    'Natural Language Processing (NLP)', 'Computer Vision', 'Data Visualization', 'Big Data Technologies',
  
   
    'Network Security', 'Penetration Testing', 'Cryptography', 'Secure Coding Practices',
    'Incident Response', 'Threat Detection and Mitigation', 'Identity and Access Management (IAM)',
    'Security Compliance',
  
    'Adobe XD', 'Sketch', 'Figma', 'User Research', 'Wireframing and Prototyping', 'Interaction Design',
    'Accessibility Design',
  
   
    'Agile Development Methodologies', 'Test-Driven Development (TDD)', 'Version Control Systems',
    'API Design and Development', 'Software Architecture and Design Patterns',
    'IoT (Internet of Things)', 'Blockchain and Cryptocurrency', 'Quantum Computing',
    'Virtual Reality (VR) and Augmented Reality (AR)',
  
   
    'Communication Skills', 'Problem-Solving', 'Teamwork and Collaboration', 'Time Management',
    'Adaptability', 'Leadership', 'Creativity', 'Critical Thinking'
  ];
  export default dataList