import React, {useEffect, useState} from "react";
import {Box, Button, Card, CardContent, Grid, TextField} from "@mui/material";
import RetryButton from "./components/RetryButton";
import InputBox from "./components/InputBox";
import { useInputStore } from "./stores/InputStore";
import { Toaster } from "react-hot-toast";
import Message from "./components/Message";
import Skills from "./components/Skills";
import AutoCompleteComponent from "./components/AutoComplete";
import useErrorStore from "./stores/ErrorStore";
import Upload from "./components/Upload";

//Comment
function App() {
  const messagesListRef = React.createRef();
  const [autoScroll, setAutoScroll] = useState(false); // Add new state variable
  // ... (other code)
  const {isVisible,userInputData,messageToBeShown,autocomplete}=useInputStore((state)=>(
    {isVisible:state.isVisible,
      autocomplete : state.autocomplete,
    setIsVisible: state.setIsVisible,
    inputType: state.inputType,
    setInputType: state.setInputType,
    currentInputType: state.currentInputType,
    setCurrentInputType: state.setCurrentInputType,
    messageToBeShown : state.messageToBeShown,
    setMessageToBeShown : state.setMessageToBeShown,
    userInputData : state.userInputData,
    
  }))

 const {error}=useErrorStore((state)=>({error : state.error}))
  
  useEffect(() => {
    if (autoScroll) {
      messagesListRef.current.scrollTop = messagesListRef.current.scrollHeight;
    }
  }, [autoScroll, messagesListRef]);

  return (
   <>
    <Grid
      container
      direction="row"
      justifyContent="center"
      
      paddingTop={'30px'}
    >
   
      <Card sx={{width: 1150, paddingBottom : '15px',}}  className="px-4 flex gap-5 flex-col justify-between">
      <Box sx={{height : 500 , overflowY : 'scroll'}}>
      <Skills/>
      {
        messageToBeShown.upload &&
        <Upload/>
      }
     
       {error !== null && (
        <div>
          <Message content={error.content} isCustomer={false} />
          <RetryButton/>
        </div>
      )}
      </Box>
      <Box>
      {
        isVisible && <InputBox/>
      }
       {
        autocomplete &&
        <AutoCompleteComponent/>
       }
       
        </Box>
      </Card>
      
    
      
    </Grid>
   
    <Toaster/>
   </>
  );
}

export default App;
