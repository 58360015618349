import React from "react";
import { Rating } from "@mui/material";
import { Link } from "react-router-dom";


const Course = ({ course }) => {
  
  function handler(){
    window.dataLayer.push({'event' : "course_click", 'course' : course.title})
  }
  return (
    <Link onClick={handler} to={`https://udemy.com${course.url}`} target="_blank" className=" w-[30%]  min-w-[250px]  border-grey-200 border bg-white rounded-md">
      <img
        src={course.image_480x270}
        alt={course.title}
        className=" object-cover w-full rounded-t-md"
      />
      <div className="py-2 px-3">
        <h1 className="text-[16px] font-bold">{course.title}</h1>
     
    {
      course.avg_rating &&   <div className="flex items-center flex-row space-x-2 my-2">
      <div className="font-semibold text-[14px] ">{course.avg_rating.toFixed(2)}</div>
 <Rating precision={0.1} size="small" readOnly value={course.avg_rating} />
    </div>
    }
     { course.discount ?
         <div className="text-[14px] ">
         <span className="font-semibold">
         {
           course.discount.price.price_string
         }
         </span>
       
         <span className="text-gray-500 ml-[5px] line-through">
           {
             course.discount.list_price.price_string
           }
         </span>
       </div>
       :
       <div className="text-[14px] ">
       <span className="font-semibold">
       {
         course.price_detail.price_string
       }
       </span>
     
     
     </div>

     }
     {
  
     }
      </div>
    </Link>
  );
};

export default Course;
