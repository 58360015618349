import React from 'react'
import { Button } from '@mui/material'
import { useInputStore } from '../stores/InputStore';
import useErrorStore from '../stores/ErrorStore';
import useJobStore from '../stores/JobStore';
import useAuthStore from '../stores/AuthStore';
import useCourseStore from '../stores/CourseStore';
const RetryButton = () => {
  const {resetJob,setNewChoice}=useJobStore((state)=>({resetJob : state.resetJob,setNewChoice : state.setNewChoice}))
  const {setError}=useErrorStore((state)=>({
    setError: state.setError
  }))
  const {setIsVisible,setAutocomplete,setCurrentInputType,resetUserInputData,setMessageToBeShown}=useInputStore((state)=>({
    setIsVisible: state.setIsVisible,
    setCurrentInputType: state.setCurrentInputType,
    setMessageToBeShown : state.setMessageToBeShown,
    resetUserInputData : state.resetUserInputData,
    setAutocomplete : state.setAutocomplete
  }))
 const {setChoice}=useCourseStore((state)=>({setChoice : state.setChoice}))
const {setFileLocation}=useAuthStore((state)=>({setFileLocation : state.setFileLocation}))
  const buttonStyle = {
        marginLeft: '43px', 
    };

  return (
    <Button
    variant='contained'
    component="label"
    style={buttonStyle}
     onClick={()=>{
      setCurrentInputType(null)
      setIsVisible(false)
      setFileLocation(null)
      setError(null)
      resetJob()
      setChoice(null)
     
      setMessageToBeShown({
        joburl : false,
        linkedinurl : false,
        email : false,
        upload : false,
        courses : false,
        certifications : false,
        verificationMail : false,
        options : false,
      })
      resetUserInputData()
      setAutocomplete(false)
     }}>Try Again</Button>
  )
}

export default RetryButton