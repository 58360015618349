import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';

const ConfirmEmail = () => {
  const location = useLocation();
  const [message, setMessage] = useState('Verifying your email...');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const clientId = queryParams.get('client_id');
    const userName = queryParams.get('user_name');
    const confirmationCode = queryParams.get('confirmation_code');

    const confirmUser = async () => {
      try {
        await Auth.confirmSignUp(userName, confirmationCode);
        setMessage('Your registration has been successfully confirmed!');
      } catch (error) {
        setMessage('There was an error verifying your email. Please try again.');
      }
    };

    confirmUser();
  }, [location.search]);

  return (
    <div className='text-center h-[400px] flex justify-center items-center'>
      <h1 className='px-7 py-8 border border-gray-500 rounded-md'>{message}</h1>
    </div>
  );
};

export default ConfirmEmail;

