import * as React from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InfoIcon from '@mui/icons-material/Info';
const MyTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.79)',
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#f5f5f9',
  },
  [`& .${tooltipClasses.tooltipArrow}`]: {
    backgroundColor: '#f5f5f9',
  },
}));

  export default function Tool({text}) {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
      setOpen(false);
    };
  
    const handleTooltipOpen = () => {
      setOpen(true);
    };
  
    return (
      <Grid item>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div className='info'>
          <MyTooltip class
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <div style={{ textAlign: 'justify' }}>
            {
              text
            }
            </div>
            }
          >
           <div className='info' onClick={handleTooltipOpen} style={{ paddingLeft: 15, textDecoration: 'underline', color: 'blue', whiteSpace: 'nowrap',  // Prevents line breaks
'@media (maxWidth: 768px)': {
  whiteSpace: 'normal', // Allows line breaks on smaller screens
}}} ><InfoIcon className='info'  style={{ color: '#B0B0B0' }}/>
</div>
          </MyTooltip>
        </div>
      </ClickAwayListener>
    </Grid>


    );
  }