import React from 'react'
import Message from './Message'
const VerificationMail = () => {
  return (
    <Message
    className="VerifiedEmailText"
    content="Thanks for verifying your email."
    isCustomer={false}
  />
  )
}

export default VerificationMail