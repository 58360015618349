import React from 'react';


const Footer=()=>{
   let containerStyle = {
    // paddingTop : '',
    fontSize: "13px",
    fontFamily: "Roboto, sans-serif", // Use the "Roboto" font
    padding: "15px",
    textAlign: "center", // Center-align the content horizontally
  };
  let emailAddress = 'info@upskillz.ai';
  
    return (
      <div className="w-full" style={containerStyle}>
        By using this application, you agree to our &nbsp; 
        <a href="/privacy" style ={{color:"#4760e2"}}>Privacy Policy</a>
        &nbsp;&amp; <a href="/tou"  style ={{color:"#4760e2"}}>Terms of Use</a>
        <div style={{ paddingTop: '17px' }}>
      Please email your feedback on{' '}
      <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
       </div>

      </div>


    );
  }


export default Footer;
