/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSkillInput = /* GraphQL */ `
  mutation CreateSkillInput(
    $input: CreateSkillInputInput!
    $condition: ModelSkillInputConditionInput
  ) {
    createSkillInput(input: $input, condition: $condition) {
      id
      Input
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSkillInput = /* GraphQL */ `
  mutation UpdateSkillInput(
    $input: UpdateSkillInputInput!
    $condition: ModelSkillInputConditionInput
  ) {
    updateSkillInput(input: $input, condition: $condition) {
      id
      Input
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSkillInput = /* GraphQL */ `
  mutation DeleteSkillInput(
    $input: DeleteSkillInputInput!
    $condition: ModelSkillInputConditionInput
  ) {
    deleteSkillInput(input: $input, condition: $condition) {
      id
      Input
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserContacts = /* GraphQL */ `
  mutation CreateUserContacts(
    $input: CreateUserContactsInput!
    $condition: ModelUserContactsConditionInput
  ) {
    createUserContacts(input: $input, condition: $condition) {
      id
      EmailId
      CertificationName
      MobileNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserContacts = /* GraphQL */ `
  mutation UpdateUserContacts(
    $input: UpdateUserContactsInput!
    $condition: ModelUserContactsConditionInput
  ) {
    updateUserContacts(input: $input, condition: $condition) {
      id
      EmailId
      CertificationName
      MobileNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserContacts = /* GraphQL */ `
  mutation DeleteUserContacts(
    $input: DeleteUserContactsInput!
    $condition: ModelUserContactsConditionInput
  ) {
    deleteUserContacts(input: $input, condition: $condition) {
      id
      EmailId
      CertificationName
      MobileNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSkill = /* GraphQL */ `
  mutation CreateSkill(
    $input: CreateSkillInput!
    $condition: ModelSkillConditionInput
  ) {
    createSkill(input: $input, condition: $condition) {
      id
      skill
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSkill = /* GraphQL */ `
  mutation UpdateSkill(
    $input: UpdateSkillInput!
    $condition: ModelSkillConditionInput
  ) {
    updateSkill(input: $input, condition: $condition) {
      id
      skill
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSkill = /* GraphQL */ `
  mutation DeleteSkill(
    $input: DeleteSkillInput!
    $condition: ModelSkillConditionInput
  ) {
    deleteSkill(input: $input, condition: $condition) {
      id
      skill
      createdAt
      updatedAt
      __typename
    }
  }
`;
