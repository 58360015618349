import { create } from "zustand";
const inputStore = (set) => ({
    isVisible : false,
    currentInputType : null,
    lastChoice : null,
    autocomplete : false,
    setAutocomplete: (value)=>(set({autocomplete : value})),
    messageToBeShown : {
        linkedinurl : false,
        email : false,
        upload : false,
        certifications : false,
        verificationMail : false,
        options : false,
        courses : false,
        courseUnavailable : false,
        closingRemark : false,
        otherSkills : false,
        topSkillCourse : false
    },
    userInputData : {
        linkedinurl : "",
        email : "",
        certification : "",
        phonenumber : ""
    },
    setUserInputData : (value)=>set((state)=>({userInputData : {...state.userInputData,...value}})),
    setMessageToBeShown : (value)=> set((state)=>({messageToBeShown :{...state.messageToBeShown, ...value}})),
    setCurrentInputType : (value) => set((state)=>({currentInputType: value})),
    setIsVisible : (value) => set((state)=>({isVisible: value})),
    resetUserInputData : ()=>(set({userInputData : {joburl : "", linkedinurl : "", email : ""}}))

});

export const useInputStore = create(inputStore)