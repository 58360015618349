import React, { useEffect } from 'react'
import { useInputStore } from '../stores/InputStore'
import Message from './Message'

import useAuthStore from '../stores/AuthStore';
import { uploadContactDetails, validateMobileNumber } from '../utils/ContactDetails';
import toast from 'react-hot-toast';

const Certifications = ({lastMessageRef}) => {
    const {setMessageToBeShown ,setUserInputData,setIsVisible,userInputData,setCurrentInputTpe}=useInputStore((state)=>({
        setMessageToBeShown : state.setMessageToBeShown,
        setUserInputData : state.setUserInputData,
        setIsVisible : state.setIsVisible ,
        userInputData : state.userInputData,
        setCurrentInputTpe : state.setCurrentInputType,
        setMessageToBeShown : state.setMessageToBeShown
    })); 
    const {email}=useAuthStore((state)=>({email : state.email}));

    useEffect(()=>{
        setIsVisible(true);
        setCurrentInputTpe("certification")
    },[])
    useEffect(()=>{
        if(userInputData.certification){
            setIsVisible(true)
            setCurrentInputTpe('phonenumber')
        }
    },[userInputData.certification])
    useEffect(()=>{
        if(userInputData.phonenumber){
         if(validateMobileNumber(userInputData.phonenumber)){
            uploadContactDetails(email, userInputData.certification, userInputData.phonenumber)
            setMessageToBeShown({closingRemark : true})
         }
         else{
            toast.error("Please enter a valid mobile number")
            setUserInputData({phonenumber : ""})
            setCurrentInputTpe('phonenumber')
            setIsVisible(true)
         }
        }
    },[userInputData.phonenumber])

  return (
    <div>
        <div ref={lastMessageRef}>
        <Message content={"Please enter the name of your desired certification in the text box below"}/>
        </div>
        {
            userInputData.certification && <div ref={lastMessageRef}> <Message className={'certification'}  isCustomer={true} content={userInputData.certification}/></div>
        }
        {
            userInputData.certification && <div><Message content={"Please enter your contact number in the text box below so we can understand your requirement better"}/></div> 
        }
        {
            userInputData.phonenumber && <div><Message className={'mobilenumber'} content={userInputData.phonenumber} isCustomer={true}/></div> 
        }

    </div>
  )
}

export default Certifications