import React from 'react'
import Message from './Message'
import { Hourglass } from 'react-loader-spinner'
const Loading = ({content}) => {
  return (
    <div className='mb-[30px]'>
        <Message isCustomer={false} content={content}/>
        <div
            style={{
              display: "flex",
              marginLeft: "45px",
              justifyContent: "left",
            }}
          >
            <Hourglass
              visible={true}
              height="30"
              width="30"
              ariaLabel="hourglass-loading"
              wrapperStyle={{}}
              wrapperClass=""
              colors={["#306cce", "#72a1ed"]}
            />
          </div>
    </div>
  )
}

export default Loading