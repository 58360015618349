import {API} from 'aws-amplify'
import { graphqlOperation } from 'aws-amplify'
import { createUserContacts } from '../graphql/mutations'
export const uploadContactDetails=(EmailId, CertificationName, MobileNumber)=>{
    API.graphql(graphqlOperation(createUserContacts,{input : {EmailId, CertificationName, MobileNumber}}))
}

export const validateMobileNumber=(MobileNumber)=>{
    MobileNumber=MobileNumber.trim()
    const phoneRegex = /^(\+?\d{1,4}[\s-]?)?(\d{10})$/;

    if(MobileNumber.length<10)
        {
            return false
        }
        
  // Test the input number against the regular expression
  return phoneRegex.test(MobileNumber);
}