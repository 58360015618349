import { v4 as uuidv4 } from "uuid";
export const isFileTypeAllowed = (file) => {
    // Define an array of allowed file extensions
    const allowedExtensions = [".docx", ".doc", ".pdf", ".txt"];

    // Get the file's extension by splitting the filename and taking the last part
    const fileExtension = file.name.split(".").pop();

    // Check if the file extension is in the list of allowed extensions
    return allowedExtensions.includes(`.${fileExtension.toLowerCase()}`);
  };

export function generateFileName(file,url=null) {
    const uid = uuidv4();
    let fileName = url!==null ? "linkedin_" : "cv_";
    const timestamp = new Date().getTime();
    if(url!==null)
    {
      const pattern = /(?:https?:\/\/)?(?:www\.)?linkedin\.com\/in\/([a-zA-Z0-9_-]+)/;
      const match = url.match(pattern);
      fileName += match[1] + timestamp + "." + uid+'.docx'
      return fileName
    }
    fileName += file.name + timestamp + "." + uid;
    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(file.name)[1];
    return fileName + "." + ext;
  }


 export const getFileContentType = (file) => {
        const mimeTypeMap = {
      pdf: 'application/pdf',
      txt: 'text/plain',
      doc: 'application/msword', // For .doc files
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // For .docx files
      
    };
    
    let contentType = 'application/octet-stream';
    // Try to infer MIME type based on file extension
    const fileExtension = file.name.split('.').pop().toLowerCase();
    if (mimeTypeMap[fileExtension]) {
      contentType = mimeTypeMap[fileExtension];
    }
    return contentType;
  };