import React, { useEffect, useRef } from "react";
import { listSkills } from "../graphql/queries";
import { API } from "aws-amplify";
import { useState } from "react";
import Message from "./Message";
import axios from "axios";
import Courses from "./Courses";
import Loading from "./Loading";
import useCourseStore from "../stores/CourseStore";
import { useInputStore } from "../stores/InputStore";
import useErrorStore from "../stores/ErrorStore";
import useAuthStore from "../stores/AuthStore";

const Skills = () => {

  const [skills, setSkills] = useState([]);
  const {method, setMethod}=useAuthStore((state)=>({method : state.method, setMethod : state.setMethod}))
  const {setError}=useErrorStore((state)=>({setError : state.setError}))
  const {messageToBeShown,setMessageToBeShown,setAutocomplete,setIsVisible,setUserInputData}=useInputStore((state)=>({messageToBeShown : state.messageToBeShown,setMessageToBeShown : state.setMessageToBeShown,setAutocomplete : state.setAutocomplete, setIsVisible : state.setIsVisible, setUserInputData: state.setUserInputData}))
  const {setIsAllowed,setTopCourse, isAllowed,courses, setTopChoice,choice,setUserSkills, setChoice,loading,setLoading,setCourses}=useCourseStore((state)=>({choice : state.choice,setChoice : state.setChoice,loading : state.loading,setLoading : state.setLoading, setCourses : state.setCourses,courses : state.courses, setUserSkills : state.setUserSkills, courses : state.courses, setTopChoice : state.setTopChoice, setIsAllowed : state.setIsAllowed, isAllowed : state.isAllowed, setTopCourse : state.setTopChoice})) 
  useEffect(() => {
    async function getSkills() {
      const skill = await API.graphql({ query: listSkills });
      
      setSkills(skill.data.listSkills.items);
    }
    
    getSkills();
  }, []);

  const handleChoice = async (value) => {
    try{
    window.dataLayer.push({
        'event' : "skill_click",
        'skill' : value
    })
    setIsAllowed(true)
    setTopChoice(null)
    setUserSkills([])
    setMethod({lastUsed : method.currentUsed})
    setUserInputData({phonenumber : "",certification : "",linkedinurl: ""})
    setMessageToBeShown({courses : false, upload : false, courseUnavailable : false, options : false, closingRemark : false, certifications : false, email : false, linkedinurl : false, topSkillCourse : false})
    setLoading(true)
    const response = await axios.post("https://s5lmem3lg2zlt4hap5ribxjbdu0dljxx.lambda-url.ap-south-1.on.aws/api/udemy", {
      skill: value,
    });
    setCourses(response.data.data.results);
    setLoading(false)
    
    }catch(error)
    {
      setLoading(false)
      setMessageToBeShown({courses : false})

      setError({content : "Unable to fetch courses. Please try again later."})
    }
  };
  useEffect(()=>{
    if(isAllowed)
    {
      setMessageToBeShown({courses : true,upload : true})
    }
  },[courses])
  useEffect(()=>{
   
  if(choice && !messageToBeShown.courseUnavailable)
  handleChoice(choice)
  },[choice])
  if (skills.length === 0) return null;
  console.log(messageToBeShown)
  return (
    <div>
      <Message
        intro_message={
          "Welcome to Upskillz.ai; please select the skills (any one) that you would like to learn."
        }
        handleChoice={(value)=>{
          setCourses([])
          setTopChoice(null)
          setChoice(null)
          setAutocomplete(false)
          setIsVisible(false)
          setMessageToBeShown({courses : false, upload : false, courseUnavialable : false, options : false, closingRemark : false, otherSkills : false, certifications : false, topSkillCourse : false})
          setTimeout(()=>{
            setChoice(value)
          },1)
        }}
        video={true}
        video_url={'https://upskillzvideo.s3.ap-south-1.amazonaws.com/upskillz_video.webm'}
        choices={skills}
        isCustomer={false}
      />
      {
        messageToBeShown.otherSkills && <Message content="Please enter the skill in the text box below."/>
      }
      {
        choice && <Message isCustomer={true} content={choice}/>
      }
      {
        loading &&  <Loading content={"Please wait while we fetch most relevant courses for you."}/>
      }
      {messageToBeShown.courses && isAllowed && (
        
            <Courses courses={courses}/>
        
      )}
      {
        messageToBeShown.courseUnavailable &&
        <Message content={"This skill is currently not supported by us and we will soon add it to our list."} isCustomer={false}/>
      }
    </div>
  );
};

export default Skills;
