import { TextField } from "@mui/material";
import {Autocomplete} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import {IconButton} from '@mui/material';
import React from 'react'
import { useState } from "react";
import useCourseStore from "../stores/CourseStore";
import { useInputStore } from "../stores/InputStore";
import dataList from "../data/SkillList";
import {API} from 'aws-amplify'
import { graphqlOperation } from "aws-amplify";
import { createSkillInput } from "../graphql/mutations";
const AutoCompleteComponent = () => {
    const [value, setValue] = useState("");
    const [inputValue, setInputValue]= useState('');
    const [input, setInput]=useState('')
    const {setChoice}=useCourseStore((state)=>({setChoice : state.setChoice}))
    const {setAutocomplete,setMessageToBeShown}=useInputStore((state)=>({setAutocomplete : state.setAutocomplete,setMessageToBeShown : state.setMessageToBeShown}))
    function createInputSkill(value)
    {
      const skillExists= dataList.some((skill)=>{
        return skill.toLowerCase()===value.toLowerCase()
      })
     
      if(skillExists)
      {
        window.dataLayer.push({
          'event' : "skill_click",
          'skill' : value
        })
      }
     
      if(!skillExists)
      {
        API.graphql(graphqlOperation(createSkillInput,{input : {Input : value}}))
        setMessageToBeShown({courseUnavailable : true, upload : true})
      }
    }
  return (
   <Autocomplete
   className=" "
   disableClearable
   value={value}
   onChange={(event, newValue) => {
     setValue(newValue);
     setInput(newValue)
   }}
   inputValue={inputValue}
   onInputChange={(event, newInputValue) => {
     setInputValue(newInputValue);
     setInput(newInputValue)
   }}
     id="disable-clearable"
     options={dataList}
   renderInput={(params) => (
     <div className="flex flex-row">
        <TextField
        placeholder="Enter your skill here..."
         sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#4C9AE4',
                borderWidth: '2px', // Increase border width
              },
              '&:hover fieldset': {
                borderColor: '#4C9AE4',
                borderWidth: '2px', // Increase border width on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: '#4C9AE4',
                borderWidth: '2px', // Increase border width on focus
              },
            },
            '& .MuiOutlinedInput-input': {
              '&:focus': {
                outline: 'none',
              },
            },
          }}
       {...params}
        value={input}
        className="flex-grow"
       variant="outlined"
     />
     <IconButton
        disabled={!input.length}
        className="ml-2 p-2 bg-blue-500 text-white rounded"
          onClick={()=>{
            setChoice(input)
            setAutocomplete(false)
            createInputSkill(input)
          }}
      >
        <SendIcon color={input.length==0 ? "disabled" : "primary"}/>
      </IconButton>
     </div>
   )}
   />
  )
}

export default AutoCompleteComponent