import {create} from 'zustand'

const errorStore=(set)=>({
    error : null,
    setError : (error) =>{
        set((state)=>({error}))
    }
})

const useErrorStore=create(errorStore)

export default useErrorStore