import React from 'react'
import Message from './Message';
import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
} from "react-share";
const ClosingRemark = () => {
  return (
    <div>
        <Message
      
      content={
        "Thank you for using Upskillz.ai. We hope it was useful for you. Please share with your friends so they can also benefit from Upskillz.ai"
      }
    >
      isCustomer={false}
    </Message>
    <div className="flex flex-row space-x-1" style={{ paddingLeft: 60 }}>
      <WhatsappShareButton
        id="whatsapp"
        url={"https://www.upskillz.ai/"}
        title={
          "I recently used Upskillz.ai and found it to be cool. You can upload your resume and find the match with AI/ML jobs. You can also get relevant courses to upskill yourself based on your resume."
        }
      >
        <WhatsappIcon size={40} round={true} />
      </WhatsappShareButton>
      <FacebookShareButton
        id="facebook"
        url={"https://www.upskillz.ai/"}
        title={
          "I recently used Upskillz.ai and found it to be cool. You can upload your resume and find the match with AI/ML jobs. You can also get relevant courses to upskill yourself based on your resume."
        }
        quote={
          "I recently used Upskillz.ai and found it to be cool. You can upload your resume and find the match with AI/ML jobs. You can also get relevant courses to upskill yourself based on your resume."
        }
      >
        <FacebookIcon size={40} round={true} />
      </FacebookShareButton>
      <LinkedinShareButton
        id="linkedin"
        url={"https://www.upskillz.ai/"}
        summary="ss"
        title={
          "I recently used Upskillz.ai and found it to be cool. You can upload your resume and find the match with AI/ML jobs. You can also get relevant courses to upskill yourself based on your resume."
        }
      >
        <LinkedinIcon size={40} round={true} />
      </LinkedinShareButton>
      <TwitterShareButton
        id="twitter"
        url={"https://www.upskillz.ai/"}
        title={
          "I recently used Upskillz.ai and found it to be cool. You can upload your resume and find the match with AI/ML jobs. You can also get relevant courses to upskill yourself based on your resume."
        }
      >
        <TwitterIcon size={40} round={true} />
      </TwitterShareButton>
    </div>
    </div>
  )
}

export default ClosingRemark