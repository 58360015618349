import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Import BrowserRouter and related components
import Header from "./Header";
import Footer from "./footer/Footer";
import App from "./App";
import PrivacyPolicy from "./footer/Privacy"; // Import your Privacy Policy component
import TermsOfUse from "./footer/Terms"; // Import your Terms of Use component
import "./index.css";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import TagManager from 'react-gtm-module';
import ConfirmEmail from "./components/ConfirmEmail";

const tagManagerArgs = {
  gtmId: 'GTM-58QC7TRN'  // Replace with your GTM ID
};
TagManager.initialize(tagManagerArgs);
Amplify.configure(awsExports);

const rootElement = document.getElementById("root"); 
const root = createRoot(rootElement);

root.render(  
  <>  
    <Router>
      <Header />
      <Routes>
        <Route path="/confirm-email" element={<ConfirmEmail />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/tou" element={<TermsOfUse />} />
        <Route path="/" element={<App />} />
      </Routes>
      <Footer />
    </Router></>
);
