import React from 'react'
import Course from './CourseCard'
import Message from './Message'
import useCourseStore from '../stores/CourseStore'
const Courses = ({courses}) => {


  return (
  
    <div>
    <Message
      isCustomer={false}
      content={
        "Thanks for sharing your interest; here are the courses that are most relevant to you"
      }
    />
    <div className="flex mb-[30px] mx-[30px]  flex-wrap gap-5 items-center flex-row  lg:justify-between">
      {courses.map((course) => (
        <Course course={course} />
      ))}
    </div>
  </div>
  )
  
}

export default Courses