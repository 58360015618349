import ClearIcon from '@mui/icons-material/Clear';
import { useState } from 'react';

const VideoModal = ({ video_url,isOpen, onClose }) => {

    if (!isOpen) return null;
  
    return (
      <div className="fixed  inset-0 z-50 flex items-center  justify-center bg-white bg-opacity-70">
        <div style={{backgroundImage: "linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%)"}} className=" sm:m-6 m-2 relative rounded-lg p-4 sm:p-8 max-w-screen-md w-[1200px]">
          <button className="absolute top-0 right-0 sm:top-2 sm:right-2 text-white" onClick={onClose}>
           <ClearIcon fontSize='small'/>
          </button>
          <video className='w-full aspect-video rounded-lg ' controls autoPlay src={video_url}/>
        </div>
      </div>
    );
  };
  

export default VideoModal

