import React from "react";

function TermsOfUse() {
  const textStyle = {
    fontFamily: "Roboto, sans-serif", // Use the "Roboto" font
    padding: "15px",
    textAlign: "justify",
    fontSize: "16px",
  };

  return (
    <div style={textStyle}>
      <div
        className="my-3"
        style={{
          fontSize: "26px",
          fontWeight: 600,
          color: "rgb(210, 56, 65)",
          margin: "auto",
          width: "fit-content",
          padding: "35px",
        }}
      >
        Terms of Use
      </div>
      <p style={{ padding: "15px" }}>
        <b>Use of the Website</b> - These terms and conditions constitute a
        binding contract as per the provisions and rules under the Information
        Technology Act of 2000, by and between the operator of the website,
        being On Demand Expert, a company duly incorporated under the provisions
        of the Companies Act, 1956 (hereinafter referred to as 'Company') and
        having its registered office at B-2, Niranjan Villa , Kanke Road,
        Ranchi, 834008, Jharkhand, India, and You. The Company is the authorised
        licensee and reseller of On Demand Experts , Referqik, Upskillz, and
        other technology products and services through www.odehire.com
        (hereinafter referred to as 'Website'). This Agreement for the Terms and
        Conditions of use of the Company's Website/s (hereinafter referred to as
        'Agreement') describes certain terms and conditions to access and use
        the Website/s of the Company by a visitor or a registered user and order
        products (hereinafter referred to as the "End User", "You" or "Your")
      </p>
      <p style={{ padding: "15px" }}>
        <b>Trademarks</b> - The trademarks, names, logos and service marks
        (collectively "trademarks") displayed on this website are trademarks of
        the respective owners. Nothing contained on this website should be
        construed as granting any license or right to You to use any trademark
        without the prior written permission of the owner.
      </p>
      <p style={{ padding: "15px" }}>
        <b>External links</b> - External links may be provided for your
        convenience, but they are beyond the control of the Company and Website
        and no representation is made as to their content. Use or reliance on
        any external links and the content thereon provided is at your own risk.
        Warranties - The Company and Website makes no warranties,
        representations, statements or guarantees (whether express, implied in
        law or residual) regarding the website.
      </p>
      <p style={{ padding: "15px" }}>
        <b>Disclaimer of liability</b> - The Company and Website shall not be
        responsible for and disclaims all liability for any loss, liability,
        damage (whether direct, indirect or consequential), personal injury or
        expense of any nature whatsoever which may be suffered by you or any
        third party (including your company), as a result of or which may be
        attributable, directly or indirectly, to your access and use of the
        website, any information contained on the website, your or your
        company's personal information or material and information transmitted
        over our system. In particular, neither the Company nor the Website or
        any third party or data or content provider shall be liable in any way
        to you or to any other person, firm or corporation whatsoever for any
        loss, liability, damage (whether direct or consequential) , personal
        injury or expense of any nature whatsoever arising from any delays,
        inaccuracies, errors in, or omission of any share price information or
        the transmission thereof, or for any actions taken in reliance thereon
        or occasioned thereby or by reason of non-performance or interruption,
        or termination thereof.
      </p>
      <p style={{ padding: "15px" }}>
        <b>Conflict of terms</b> - If there is a conflict or contradiction
        between the provisions of these website terms and conditions and any
        other relevant terms and conditions, policies or notices, the other
        relevant terms and conditions, policies or notices which relate
        specifically to a particular section or module of the website shall
        prevail in respect of your use of the relevant section or module of the
        website.
      </p>
      <p style={{ padding: "15px" }}>
        <b>Severability</b> - Any provision of any relevant terms and
        conditions, policies and notices, which is or becomes unenforceable in
        any jurisdiction, whether due to being void, invalidity, illegality,
        unlawfulness or for any reason whatever, shall, in such jurisdiction
        only and only to the extent that it is so unenforceable, be treated as
        void and the remaining provisions of any relevant terms and conditions,
        policies and notices shall remain in full force and effect.
      </p>
      <p style={{ padding: "15px" }}>
        <b>Applicable laws</b> (choice of venue and forum) - Use of this website
        shall in all respects be governed by the laws India, regardless of the
        laws that might be applicable under principles of conflicts of law. The
        parties agree that the courts located in Ranchi, Jharkhand, India shall
        have exclusive jurisdiction over all controversies arising under this
        agreement and You agree that venue is proper in those courts.
      </p>
      <p style={{ padding: "15px" }}>
        <b>Information Disclaimer</b> - The information (including, without
        limitation, advice and recommendations) on the website is intended
        solely as a general educational aid. Your use of the site is subject to
        the additional disclaimers and caveats that may appear throughout the
        website. The Company and Website and its agents assume no
        responsibilities for any consequence relating directly or indirectly to
        any action or inaction you take based on the information, services, or
        other material on the website. While we shall strive to keep the
        information on the website accurate, complete, and up-to-date, we cannot
        guarantee, and will not be responsible for, any damage or loss related
        to the accuracy, completeness, or timeliness of the information on the
        website.
      </p>
      <p style={{ padding: "15px" }}>
        <b>Legal Notice</b> - All content of this Internet site is protected by
        worldwide copyright laws. You may download content only for your
        personal use for non-commercial purposes but modification or further
        reproduction of the content is not permitted. The content may otherwise
        not be copied or used in any way. The owners of this site will use
        reasonable efforts to include up-to-date and accurate information in
        this Internet site, but makes no representations, warranties, or
        assurances as to the accuracy, currency, or completeness of the
        information provided. The owners of this site shall not be liable for
        any damages or injury resulting from your access to, or inability to
        access, this Internet site, or from your reliance on any information
        provided at this Internet site. This site is for residents of and/or
        domiciled in India. This Internet site may provide links or references
        to other sites but the owners of this site have no responsibility for
        the content of such other sites and shall not be liable for any damages
        or injury arising from that content. Any links to other sites are
        provided as merely a convenience to the users of this Internet site. Any
        personally identifiable information in electronic communications to this
        Internet site is governed by this site's Privacy Policy. The Company and
        Website shall be free to use or copy all other information in any such
        communications, including any ideas, inventions, concepts, techniques or
        know-how disclosed therein, for any purposes. Such purposes may include
        disclosure to third parties and/or developing, manufacturing and/or
        marketing goods or services. The sender of any communications to this
        Internet site or otherwise to the owners of this site shall be
        responsible for the content and information contained therein, including
        its truthfulness and accuracy.
      </p>
      <p style={{ padding: "15px" }}>
        <b>Payment Processing</b> - We use Third Party Payment Gateway to
        process payments securely. We as a merchant shall be under no liability
        whatsoever in respect of any loss or damage arising directly or
        indirectly out of the decline of authorization for any Transaction, on
        Account of the Cardholder having exceeded the preset limit mutually
        agreed by us with our acquiring bank from time to time.
      </p>
      <p style={{ padding: "15px" }}>
        <b>Communication</b> - If a user fills a form, participates in a
        contest, or purchases product, user agrees to receive communication from
        the company or its communication partners. The Company or its
        communication partners may Call the user over the phone, send Electronic
        mails (email) and Short Message to user Mobile Numbers (SMS) wherever
        necessary for the ease of communication with the user. The content of
        the email and SMS would be controlled by Company and Website and may not
        be copied anyway. If user wishes to opt-out, user can get in touch with
        the company and will be unsubscribed from all communications by sending
        an email to info@referqik.com. Until user opts-out, user will continue
        to receive communication from the Company or its communication partners.
      </p>
    </div>
  );
}

export default TermsOfUse;
