export function generateRandomPassword(length) {
    const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
    const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numerals = "0123456789";
    const symbols = "!@#$%^&*()-_=+[]{}|;:,.<>?";

    const requiredChars = [lowercaseChars, uppercaseChars, numerals, symbols];

    if (length < 8) {
      return "Password length should be at least 8 characters";
    }

    let password = "";

    // Ensure at least one character from each required character set
    for (const set of requiredChars) {
      const randomIndex = Math.floor(Math.random() * set.length);
      password += set.charAt(randomIndex);
    }

    // Fill the rest of the password with random characters
    const remainingLength = length - requiredChars.length;
    const allChars = lowercaseChars + uppercaseChars + numerals + symbols;

    for (let i = 0; i < remainingLength; i++) {
      const randomIndex = Math.floor(Math.random() * allChars.length);
      password += allChars.charAt(randomIndex);
    }

    // Shuffle the password to randomize the order
    password = shuffleString(password);

    return password;
  }

  // Helper function to shuffle a string
  function shuffleString(string) {
    const array = string.split("");
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array.join("");
  }