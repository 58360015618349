/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSkillInput = /* GraphQL */ `
  query GetSkillInput($id: ID!) {
    getSkillInput(id: $id) {
      id
      Input
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSkillInputs = /* GraphQL */ `
  query ListSkillInputs(
    $filter: ModelSkillInputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSkillInputs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Input
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserContacts = /* GraphQL */ `
  query GetUserContacts($id: ID!) {
    getUserContacts(id: $id) {
      id
      EmailId
      CertificationName
      MobileNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserContacts = /* GraphQL */ `
  query ListUserContacts(
    $filter: ModelUserContactsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        EmailId
        CertificationName
        MobileNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSkill = /* GraphQL */ `
  query GetSkill($id: ID!) {
    getSkill(id: $id) {
      id
      skill
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSkills = /* GraphQL */ `
  query ListSkills(
    $filter: ModelSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSkills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        skill
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
