import { Document, TextRun,Packer, Paragraph } from 'docx';
async function JsontoDoc (apiData) {

const doc = new Document({
  sections: [
      {
          properties: {},
          children: [
              new Paragraph({
                  children: [
                      new TextRun({
                          text: JSON.stringify(apiData),
                          bold: false,
                      }),
                  ],
              }),
          ],
      },
  ],
})

let blob = await Packer.toBlob(doc)
return blob
}

export default JsontoDoc