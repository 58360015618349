import React, { useState } from 'react'
import { useInputStore } from '../stores/InputStore';
import { TextField } from '@mui/material'
import {IconButton} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

const InputBox = ({autocomplete}) => {

  const [input, setInput]=useState("")
    const { currentInputType,setUserInputData, setIsVisible} = useInputStore((state) => ({setUserInputData : state.setUserInputData,setMessageToBeShown : state.setMessageToBeShown, currentInputType: state.currentInputType, setInputType: state.setInputType, setIsVisible: state.setIsVisible}))
    let placeholdertext
    if (currentInputType === "linkedinurl") {
      placeholdertext = "Enter your LinkedIn URL here..."
    }
    else if(currentInputType === "email"){
      placeholdertext = "Enter your email here..."
    }
    else if(currentInputType==='joburl'){
      placeholdertext="Enter your LinkedIn URL with https://www..."
    }
    else if(currentInputType==='certification'){
      placeholdertext="Enter certification name here..."
    }
    else if(currentInputType==='phonenumber'){
      placeholdertext="Enter your 10-digit phone number (without adding 0 or +91)"
    }
    else{
      placeholdertext="Enter URL here..."
    }
    const [results, setResults]=useState([])
    
    const handleInputChange = (e) => {
      if(autocomplete)
        {

        }
      setInput(e.target.value);
    };
  
    const handleSendMessage = () => {
      if (input.trim()) {
        setUserInputData({
          [currentInputType]: input
        }
        )
        setIsVisible(false)
      }
    };
  
  return (
    <div className="flex items-center">
     
      <TextField
        variant="outlined"
        value={input}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#4C9AE4',
              borderWidth: '2px', // Increase border width
            },
            '&:hover fieldset': {
              borderColor: '#4C9AE4',
              borderWidth: '2px', // Increase border width on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: '#4C9AE4',
              borderWidth: '2px', // Increase border width on focus
            },
          },
          '& .MuiOutlinedInput-input': {
            '&:focus': {
              outline: 'none',
            },
          },
        }}
        onChange={handleInputChange}
      className={`flex-grow`}
        placeholder={placeholdertext}
        InputProps={{
          className: "border-none outline-none",
        }}
      />

      <IconButton
        onClick={handleSendMessage}
        className="ml-2 p-2 bg-blue-500 text-white rounded"
        disabled={!input.trim()}
      >
        <SendIcon color={!input.trim() ? "disabled" : "primary"}/>
      </IconButton>
    </div>
  )
}

export default InputBox