import PersonIcon from "@mui/icons-material/Person";
import avatar from "../assets/logo.png";
import { Avatar, Box, Chip, Typography } from "@mui/material";
import VideoModal from "./VideoModal";
import { useState } from "react";
import { useInputStore } from "../stores/InputStore";

import useCourseStore from "../stores/CourseStore";
import useAuthStore from "../stores/AuthStore";
function Message(props) {
  const {setIsVisible,setCurrentInputType,setMessageToBeShown,resetUserInputData,setAutocomplete}=useInputStore((state)=>({setMessageToBeShown : state.setMessageToBeShown,setIsVisible:state.setIsVisible, setCurrentInputType : state.setCurrentInputType,resetUserInputData : state.resetUserInputData,setAutocomplete : state.setAutocomplete}));
  const [isModalOpen, setIsModalOpen] = useState(false);
 
  const {setLoading,setCourses,setChoice, setUserSkills,setTopChoice, setIsAllowed}=useCourseStore((state)=>({setCourses : state.setCourses,setChoice : state.setChoice, setUserSkills : state.setUserSkills,setTopChoice : state.setTopChoice, setIsAllowed : state.setIsAllowed, setLoading: state.setLoading}))
  const {fileLocation,setMethod, method}=useAuthStore((state)=>({fileLocation : state.fileLocation, method :state.method, setMethod : state.setMethod}))
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
    const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className={props?.className}>
      <Box
        sx={{
          my: 2,
          display: "flex",
          flexFlow: "row",
          justifyContent: props.isCustomer ? "right" : "left",
        }}
      >
        {!props.isCustomer && (
          <Avatar sx={{ mr: 1, bgcolor: "primary.main" }}>
            <img src={avatar} alt="Chatbot avatar" width={32} />
          </Avatar>
        )}
        <Box className={''}>
          {props.content && (
            <Typography
            className={`break-words ${props.className==='linkedin' ? 'break-all' :''}`}
              gutterBottom
              variant="body2"
              component="div"
              sx={{ mt: 1.5, fontSize: "16px" }}
            >
              {props.content}
            </Typography>
          )}
          <Typography
            gutterBottom
            variant="body2"
            component="div"
            sx={{ mt: 1.5, fontSize: "16px" }}
          >
            <span>{props.intro_message}</span>{" "}
            <span>{props.job_selection_message}</span>{" "}
            {props.video && (
              <span>
                <span
                  onClick={handleOpenModal}
                  className="underline text-blue-500 cursor-pointer"
                >
                  (How does Upskillz work)
                </span>

                <VideoModal
                  video_url={props.video_url}
                  isOpen={isModalOpen}
                  onClose={handleCloseModal}
                />
              </span>
            )}
          </Typography>

          {props.image && (
            <a
              href={"https://www.udemy.com" + props.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={props.image}
                alt="Bot response"
                style={{ width: "20%" }}
              />
            </a>
          )}
          {!props.isCustomer && props.choices && !props.skills && (
            <Box sx={{ mt: 1 }}>
              {props.choices.map((choice, index) => (
                <Chip
                  key={index}
                  label={choice.skill}
                
                  onClick={() => props.handleChoice(choice.skill)}
                  sx={{ height: 39, mr: 0.8, mb: 0.5, fontSize: "14px" }}
                />
              ))}
                   <Chip
          label={"Other Skills"}
          component="label"
       
          sx={{ height: 39, mr: 0.8, mb: 0.5, fontSize: "14px" }}
          onClick={()=>{
          setIsAllowed(false)
          setAutocomplete(true)
          setLoading(false)
          setCourses([])
          setUserSkills([])
          setChoice(null)
          setMessageToBeShown({courses : false, upload : false, courseUnavailable : false, options : false, closingRemark : false, otherSkills : true, certifications : false, email : false, linkedinurl : false, topSkillCourse : false})
          }}
        >
        </Chip>
            </Box>
          )}

          {!props.isCustomer && props.choices && props.skills && (
            <Box sx={{ mt: 1 }}>
              {props.choices.map((choice, index) => (
                <Chip
                  onClick={()=>{
                    setTopChoice(choice.name)

                    setMessageToBeShown({topSkillCourse : true})
                  }
                  }
                  key={index}
                  label={choice.name}
                  sx={{ height: 39, mr: 0.8, mb: 0.5, fontSize: "14px" }}
                />
              ))}
            </Box>
          )}
         
        </Box>
        {props.isCustomer && (
          <Avatar sx={{ ml: 1, bgcolor: "primary.main" }}>
            <PersonIcon sx={{ fontSize: "32px" }} />
          </Avatar>
        )}
      </Box>
    </div>
  );
}
export default Message;
